$primary-color: #09819A;
@import '~loaders.css/src/animations/ball-pulse-sync.scss';

.section-loader-reload-btn {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
}
