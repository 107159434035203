.line{
	border: 1px solid #09819A;
}

.card{
	margin: auto auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 10px;
    padding: 50px 15px 50px;
    max-width: 34em;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    min-height: 400px;
    @media only screen and (max-width: 400px) {
      padding-left:0;
      padding-right: 0;
    }
}

.title{
    font-size: 170%;
    line-height: 1.3;
    margin-bottom: 0.25rem !important;
    font-weight: 500;
    color: #193652;
}

.logo{
    margin-bottom: 2px;
    text-align: center;
}

.logo_div{
    padding-bottom: 3rem;
}

.accounts-button{
    font-size: 15px !important;
    padding: 0.75rem 2rem 0.7rem !important;
}