@import './react-js-pagination.css';
@import '~react-screen-keyboard/src/Keyboard.css';
@import'~react-bootstrap-modal/lib/css/rbm-complete.css';
@import '~flatpickr/dist/flatpickr.css';
@import '~tachyons';

@font-face {
   font-family: MuseoSans;
   font-weight: 100;
   src: url('./fonts/MuseoSans_100.otf');
 }
 @font-face {
   font-family: MuseoSans;
   font-weight: 300;
   src: url('./fonts/MuseoSans_300.otf');
 }
 @font-face {
   font-family: MuseoSans;
   font-weight: 500;
   src: url('./fonts/MuseoSans_500.otf');
 }
 @font-face {
   font-family: MuseoSans;
   font-weight: 700;
   src: url('./fonts/MuseoSans_700.otf');
}

body, html {
  font-family: 'MuseoSans', Roboto !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  font-size: 14px;
  color: #001E2E;
  position: relative;
  min-height: 100%;
  overscroll-behavior: none;
}

html {
  height: 100%;
  box-sizing: border-box;
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -1200px 0
  }
  100%{
    background-position: 1200px 0
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 1200px 104px;
  position: relative;
}


.job-notes-border {
  border-top: 1px solid #09819A !important;
}

.task_list_container {
  font-size: 48px !important;
}

.meal_text_container {
  border: 1px solid #09819A !important;
  width: 87.5% !important;
  margin: auto;
  margin-top: 4rem;
}

.btn_meal {
  border-radius: 4px !important;
  font-weight: 600;
  font-size: 1rem;
}

.job_comment_user {
  text-align: center;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


.job_notes_text {
  font-weight: 400;
  font-family: 'MuseoSans', Roboto !important;
  font-size: 2rem;
  border-bottom: 2px solid #09819A;
  padding-bottom: 0.5rem;
  color: #001E2E;
}

.btn-custom-color {
  background-color: red;
  color: #FFF;
  border-radius: 0.2rem;
  padding: 1rem;
  border: none;
  margin-bottom: 1rem;
}

/* Font color #242424 */

.custom_black_font {
  color: #242424 !important;
}

/* CSS Styles for the Dashboard Page */

.vertical_center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.employee-name {
  margin: 0;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.team_button {
  margin-bottom: 1.5rem;
  width: 48%;
  float: right;
}

.team_button  button {
  width: 100% !important;
}

.department_button {
  margin-bottom: 1.5rem;
  width: 48%;
}

.department_button > button {
  width: 100% !important;
}

.custom_border {
  font-weight: 500;
  font-size: 15px;
  border-radius: 0 0.25rem 0.25rem 0;
  cursor: pointer;
  width: 17%;
}
@media only screen and (min-width: 399px) and (max-width: 440px) {
  .custom_border_bilingual{
    font-size: 12px !important;
  }
}
.btn-color {
  border: currentColor;
  background-color: #09819A;
}
.btn-color:hover, .btn-color:focus {
  background-color: #001e2e!important;
  border: #001e2e!important;
}
.btn-custom-red {
  background-color: #D83F3F;
}
.btn-grey{
  border: #ccc;
  background-color: #ccc;
}
.btn-canecl-color {
  border: currentColor;
  background-color: red;
}
.btn-custom-red {
  border: none;
  background-color: #D83F3F;
}
.btn-red{
  border: red;
  background-color: red;
}

.btn-blue{
  border: currentColor;
  background-color: rgb(0, 30, 46);
}
.search-text {
  padding-right: 6.5rem;
  height: 32px !important;
}

.logo-img {
  max-width: fit-content;
}
.header-height {
  height: 76px;
}
.keyboard-modal .modal-content {
  box-shadow: none;
  border: none;
  background:none;
}

.keyboard-modal .modal-dialog {
  height:100% ;
  margin:auto;
}
 .keyboard-modal .modal-content {
   box-shadow: none;
   border: none;
   background:none;
  position: absolute;
  width: 100%;
  bottom: 0;
  font-family: 'MuseoSans', Roboto !important;
}

.bg-primary {
  background-color: #077289 !important;
}

.input-icon {
  margin-top: -51px !important;
  margin-right: 9px !important;
 }

.keyboard-icon {
  top: 0px;
  right: 6px;
  position:absolute;
}
.dashboardkeyboard-icon {
  top: 0px;
  right: 10px;
  position:absolute;
}
.text-keyboard-icon {
  top: 16px;
  right: 6px;
  position:absolute;
}
.eye-icon {
  top:8px;
  right:41px;
  position:absolute;
}
.clear-icon {
  top: 12px;
  right: 50px;
  position: absolute;
}

.filter-clear-icon{
  top: 19px;
  right: 9px;
  position: absolute;
  cursor: pointer;
}

.error-text {
  color: red;
  min-height: 20px;
}
.height-list{
  height: 3rem;
}
.dark-grey-bg{
  background-color: #efefef;
}
.white-bg{
  background-color: white;
}
.table-bottom{
  border-bottom: 1px solid #efefef;
}
.button2link {
  color: #162d6e;
  border-color: #162d6e;
  background-color: transparent;
}
.button2link:hover {
  background: #162d6e;
  color: #fff;
  border-color: #162d6e;
  }
.back-arrow {
  cursor: pointer;
}

.timecard-sub-header {
  color: #86939E !important;
}
.timecard-list-color {
  color :  #00aced !important ;
}
.timecard-list-footer-color{
  background-color : #C4D79B !important;
}
.timecard-list-header-color {
  background-color :  #B7C0C8 !important ;
}
.timecard-detail-color {
  color: #34BE9E !important;
}

.text-color {
  color: #193652 !important;
}

.timecard-section {
  background-color: #B7C0C8 !important;
}

button, input {
  font-family: 'MuseoSans', Roboto !important;
}

.modal-w-50 {
  width: 50% !important;
}
button:disabled {
    opacity: .5;
}

.dropdown-arrow {
  border: solid #666;
  border-width: 0 1px 1px 0;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: 7px;
  right: 20px;
}

.dropdown-width {
  max-width: none !important;
  width: 100% !important;
  box-shadow: 0px 2px 5px #00000029;
  border: none;
  border-radius: 5px;
}
.dropdown-button {
  background-color: #FFF;
  color: #242424;
  height: 32px;
  padding-right: 20px;
}
.dropdown-button::after {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-left: 0.3em;
  vertical-align: middle;
  content: "";
  position: absolute;
  right: 15px;
  top: 10px;
  border-top: 2px solid black;
  border-left: 2px solid black;
  transform: rotate(-135deg);
}

.timecardDropdown {
  background-color: #FFF;
  color: #242424;
  height: 32px;
  padding-right: 20px;
}
.timecardDropdown::after{
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.3em;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 6px;
  top: 14px;
}
.tc-dropdown-width{
  max-width: none !important;
  width: 100% !important;
}

.dropdown-button:focus {
  outline: none;
}

.max-h4 {
  max-height: 12rem;
}
.m-auto {
  margin: auto;
}
@media (max-width: 767px) {
  .sm-search {
    float: left !important;
  }
  .sm-tab h1 {
    font-size: 1.2rem;
  }
  .sm-tab h2, .sm-tab h3,
  .sm-tab .f4, .sm-tab .f4-m {
    font-size: 1.1rem;
  }
  .sm-tab button img {
    vertical-align: middle;
  }
}

@media (max-width: 600px) {
  .sm-tab {
    padding-top: 8px;
  }
  .sm-tab h1 {
    font-size: 1.2rem;
  }
  .sm-tab h2, .sm-tab h3,
  .sm-tab .f4, .sm-tab .f4-m {
    font-size: 1.1rem;
  }
  .sm-tab .pv3 {
    padding-top: .7rem;
    padding-bottom: .7rem;
  }
  .sm-tab .mr4-m {
    margin-right: 1rem;
  }
  .sm-tab .ml4-m {
    margin-left: 1rem;
  }
  .sm-tab .meal-btn {
    width: 45%;
  }
  .sm-tab button img {
    vertical-align: middle;
  }
}

button:focus {
  outline: none;
}

.close{
  font-size: 26px;
  opacity: 0.7;
}

.keyboard-modal-header{
  position: absolute;
  right: -3px;
  top: -23px;
  padding: 0px;
  border: none;
  z-index: 100;
  border-bottom: unset !important;
}

.keyboard-modal-body{
  padding-top: 0px;
}

.keyboard-modal-header .close{
  height: 32px;
  width: 32px;
  background: #fff;
  border-radius: 50%;
  color: #000;
  padding-bottom: 2px;
  opacity: 1;
}

.keyboard-submit-button{
  border: currentColor;
  background-color: #09819A;
}

.collapse-div{
  background-color: #efefef;
  border: 2px solid #efefef;
}

.calendar-input{
  color: #4E4D4A !important;
  width: 52%;
}

.form-control-feedback {
  font-size: 12px;
  min-height: 18px;
}
.form-control {
  font-size: 14px !important;
  line-height: 1.2 !important;
}
.form-control[readonly] {
  background-color: white !important;
}
.form-control:disabled {
   background-color: #e0e0db !important;
}
.form-control:focus {
  border-color: #162d6e !important;
}
.calendar-icon{
  position: absolute;
  background: #E2ECF1;
  padding: 7px;
  border-left: none;
  border-bottom-right-radius: .25rem;
  border-top-right-radius: .25rem;
  height: 32px;
  padding: 7px;
  border-left: none;
  border-bottom-right-radius: .25rem;
  border-top-right-radius: .25rem;
  height: 32px;
}

.margin-list{
  margin: auto;
}
.scroll-list{
  overflow-y: auto !important;
  height: 415px !important;
}
.a2z-dictionary{
  display: inline-block;
  position: absolute;
  color: #001E2E;
  background-color: #DDDDDD;
  padding-top: 10px;
  font-size: 13px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: default;
}
.list-wrapper{
  display: inline-block;
  width: 97%;
}
.width-btn{
  width: 25%;
}

.btn-selected{
  background-color: #09819a !important;
  color : white !important;
}
.btn-inactive{
  background-color: #B1BABF !important;
  color : #444444 !important;
}
.width-dict{
  width: 30px !important;
  outline: none !important;
  cursor: pointer;
}

.width-hash{
  width: 30px !important;
  outline: none !important;
  cursor: default;
}
.width-dict :focus{
  cursor: pointer;
}

.width-dict:hover{
  background-color: gray;
  opacity: 1;
}
.load-image{
  height: 40px;
}

.focus-class{
  opacity: 1;
  color: #162d6e !important;
  text-decoration: underline;
}

.reset-link{
  text-decoration: underline !important;
  font-style: italic !important;
  cursor: pointer !important;
  color: #162d6e !important;
}

.custom-modal{
  border-bottom: 2px solid #09819A;
}

.custom-heading h2{
  font-weight: 500;
}

.custom-width .modal-content{
  margin-left: 8%;
  max-width: 500px;
}

.custom-button button{
  height: 45px;
  font-size: 17px;
}

.custom-text{
  font-weight: 400;
}

.header-alignment{
  display: contents;
}

.gallery-modal-body{
  position: relative;
  padding: 0px;
}

.submit-icon{
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}

.back-icon{
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

.button-role{
  cursor: pointer;
}

.resizeHandler {
  position: relative;
  cursor: pointer;
}

.overview-Div{
  display: inline-block;
  position: relative;
  z-index: 2;
}


.overview-move {
  position: absolute;
  bottom: 14px;
  right: 0 !important;
  transform: translateY(-50%);
  width: 100%;
  margin: 0;
  z-index: 1;
  border: 1px solid  #077289;
}

.overview-move-next{
  position: absolute;
  bottom: -29px;
  right: 0 !important;
  transform: translateY(-50%);
  width: 100%;
  margin: 0;
  z-index: 1;
  border: 1px solid  #077289;
}

.div-height{
  height: 100%;
  width: 110%;
  float: right;

}

.align-job-details{
  vertical-align: middle;
  display: table-cell;
  padding-left: 30px;
}

.custom-width{
  width: 100%;
}

.dash-boder {
  border-style: dashed !important;
  border-color: #e1e1e1 !important;
}

.overview-fix{
  position: fixed;
  width: 100%;
  display: contents;
}

.overview-movable {
  border: 1px solid #0c3778 !important;
  cursor: pointer;
  position: absolute;
  bottom: -18px !important;
  /* top: 157px; */
  width: 100%;
}

.resizeArrow{
  position: absolute;
  top: -3px;
  width: max-content;
  left: -115px;
  background-image: url(./arrow.png);
  width: 114px;
  height: 26px;
  color: white;
  text-align: center; 
  font-size: 14px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.disabledResizeArrow{
  position: absolute;
  top: -3px;
  width: max-content;
  left: -115px;
  background-image: url(./arrowDisabled.png);
  width: 114px;
  height: 26px;
  color: white;
  text-align: center;
  font-size: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.resizeArrowNextDay{
  position: absolute;
  top: 16px;
  width: max-content;
  left: -115px;
  background-image: url(./arrow.png);
  width: 114px;
  height: 26px;
  color: white;
  text-align: center; 
  font-size: 14px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.showDuration{
  color: green;
  top: 11px;
  position: relative;
  z-index: 3;
  left: 46%;
  background-color: white;
  width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 15px;
}

.svg-fill .cls-2{
  fill: #09819a;
  stroke: #656565;
  stroke-linejoin: round;
}

.filter-component{
  display: flex;
  margin: auto;
  margin-bottom: 40px;
  margin-top: 40px;
  padding-right: 6px;
  padding-left: 6px;
}

.filter-component div{
  flex-grow: 1;
  height: 48px;
}

.filter-component input{
  height: 48px;
  font-size: 14px;
}

.dashborad-filter-component input::placeholder{
  padding-right: 40px !important;
}

.dropdown-custom .dropdown-width{
  width: 40% !important;
}

.dropdown-options{
    position: absolute;
    top: 15px;
    right: 63px;
    width: 80px;
    height: 27px;
    /* width: 60px; */
    text-align: center;
}

.correction-request{
  cursor: pointer;
  color: white;
  width : 100%;
}

.arrow_box {
  text-align: right;
  position: absolute;
  /* left: 34px; */
  width: 95px;
  left: 40px;
  background-color: #F2F2F2;
  border: none;
  box-shadow: 0px 0px 4px 0px #F2F2F2;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  height: 31px;
  border-radius: 2px;
  top: -4px;
}

.menu-option{
  position: absolute;
  left: 105px;
  top: -4px;
}

.fill-green .cls-2{
  fill: #09819A !important;
}

.enableScroll{
  overflow: auto;
  height: 50vh;
}

.custom-width .close{
  font-size: 23px;
  margin-top: 2px
}



@media only screen and (min-device-width: 400px) and (max-device-width: 766px) {
  .face_detector .modal-dialog {
    width: 80% !important;
    margin: 30px auto;
  }
  .language-custom-modal .modal-dialog{
    width: 80% !important;
    margin: 30px auto;
  }
}

@media only screen and (min-device-width: 500px) and (max-device-width: 900px) {
  .face-setup-sm {
    height: 50vh;
  }
}
@media only screen and (min-device-width: 620px) and (max-device-width: 900px) {
  .snap-btn-sm {
    margin-right: 18%;
  }
}
@media only screen and (max-device-width: 619px) {
  .snap-btn-sm {
    margin-right: 0;
  }
}

@media only screen and (min-device-width: 400px) and (max-device-width: 900px) {
  .overview_screen_width{
    width: 70%;
  }
}

.scroll_list{
  overflow: auto;
  height: 200px;
  padding: 2px 3px;
}

.job_filter_component{
  width: 100%;
  display: flex;
}

.job_filter_component input{
  height: 32px;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}


.job_filter_component .custom_border{
  height: 32px;
}

.job_filter_component small{
  display: none;
}

.infinite-scroll-component, .scroll_list{
  scrollbar-width: thin;
}

.scroll_list::-webkit-scrollbar , .infinite-scroll-component::-webkit-scrollbar{
    width: 7px;
    background-color: white;
}

.scroll_list::-webkit-scrollbar-track, .infinite-scroll-component::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.scroll_list::-webkit-scrollbar-thumb, .infinite-scroll-component::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #cecccc;
}

.job-list{
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}


.arrow{
  border: solid #8e8d8d;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
}

.arrow_div{
  flex-grow: 1;
  text-align: center;
/*  position: absolute;
  width: 100%;
  bottom: -25px;*/

}

.upward_arrow{
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.downward_arrow{
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}


.resizer_arrow{
  border: solid #077289 ;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
}


.bounce_disabled{
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  -user-select: none;
  -webkit-user-select:  none; 
  -webkit-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
}

.margin_for_top{
  margin-bottom: 30px !important;
}

@media only screen and (min-device-width: 700px) and (max-device-width: 900px) {
  .margin_for_top{
    margin-bottom: 40px !important;
  }
}

.text_select_disabled{
 -moz-user-select: none;
 -webkit-user-select: none;
 -ms-user-select: none;
  user-select: none;
}

.textOverflow{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width:90%;
}

.newAllocation{
  bottom: -32px;
  position: absolute;
  z-index: 999;
  left: 0px;
}

.addOnTop path{
  fill: #bbb8b8;
}

@media only screen and (min-device-width: 700px) and (max-device-width: 900px) {
  .ipadMargins{
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .ipadBottom{
    margin-bottom: 35px;
  }

}

.showDisabledDragger{
  margin-bottom: -9px;
  position: relative;
  z-index: 2;
}

@media only screen and (min-device-width: 500px) and (max-device-width: 900px) {
  .custom_ipad_width{
    width: 4% !important;
  }
}

.edit_assign_popup .modal-content{
  max-width: 500px;
  margin-left: 10%;
}

.edit_time_popup .modal-content{
  justify-content: center;
  display: flex;
  width: fit-content !important;
}

.edit_time_popup .modal-body{
  padding: 0px;
}

.edit_time_popup .modal-dialog{
  width: 100px!important;
}

.edit_assign_popup .modal-header{
  border-bottom: 2px solid #4bb147;
}

.custom_width_picker .showTimeInput{
  width: 114%;
}

.timepicker_display .timepicker-container{
  display: inline-block;
}

.timepicker_display .timepicker-modal{
  top: 100% !important;
}

.customHeightFlatpickr .flatpickr-mobile{
  height: 16px !important;
  width: 80%;
}

.customHeightFlatpickr .flatpickr-input{
  height: 32px;
  width: 80%;
}

.custom-space{
  line-height: 4rem;
  font-size: 15px;
}

.custom-space-bilingual{
  line-height: 4rem;
  font-size: 15px;
}

.textLeft{
  text-align: left;
}

.JobTaskTimeText{
  font-weight: 400 !important;
  margin-top: -10px;
}

.welcome-text{
  font-size: 240%;
  line-height: 1;
}

.gotologin-button{
  padding: 0.7rem 1.2rem 0.7rem;
  font-size: 16px;
  font-weight: 400;
}

.dashboard-filter-component{
  margin-top: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  height: 37px !important
}

.close{
  color: #09819A !important;
  opacity: unset !important;
}

.modal-header {
    padding: 20px 15px 10px;
    border-bottom: 2px solid #09819A;
    min-height: 16.42857143px;
}

.modal-header .close{
  margin-top:2px;
}

.modal-title {
  font-size: 17px;
}

.dashboard-search{
  height: 37px;
  box-shadow:0px 2px 5px #00000029;
}
.modal-backdrop.in {
  opacity: 0.5;
  background-color:#132F3D;
  filter: alpha(opacity=50);
}

.font-3_15{
  font-size: 15px;
  font-weight: 300;
  color:#001E2E;
}
.font-3_11{
  font-size: 11px;
  font-weight: 300;
  color:#001E2E;
}

.keyboard-button{
  height: 3em;
  font-size: 16px;
  font-weight: 400;
}

.ui_card{
  width: 33.33%;
}

.custom-width-video{
  width: 100%
}

@media only screen and (max-width: 1200px) {
  .ui_card{
    width: 38%;
  }
  .heading-padding{
    padding-left: 215px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .ui_card{
    width: 48%;
  }
}

@media only screen and (max-width: 768px) {
  .ui_card{
    width: 58%;
  }
  .custom-width-video{
    width: 100%
  }
}

@media only screen and (max-width: 640px) {
  .ui_card{
    width: 77%;
  }
  .custom-width-video{
    width: 70%
  }
}

@media only screen and (max-width: 480px) {
  .ui_card{
    width: 93%;
  }
  .custom-width-video{
    width: 62%
  }
}

@media only screen and (max-width: 400px) {
  .ui_card{
    width: 95%;
  }
}

.flex1{
  flex:1;
}

.flex06{
  flex:0.6;
}

.grey-color path{
  fill: grey !important;
}

.login-error{
  height: 50px;
  background-color: #f5caca;
  padding-top: 16px;
  color:  #EDD5D5;
  text-align: center;
  font-size: 16px;
}

.login-text{
  color: #CE4949
}

.search-name-img{
  right: -40px;
  top: 2px;
  margin-right: 45px;
  position: absolute;
}

.language-setting-container{
  line-height: 3rem;
}

.language-setting-content{
  border-bottom: 2px solid #E7EFF2;
}

.checkicon-position{
  float: right;
}

.language-custom-modal .modal-dialog{
  width: 400px;
}

.bilingual-button-width{
  width: auto;
}

@media only screen and (max-width: 1100px) {
  .custom-bilingual-flex{
    flex: 2.8 !important;
  }
  .custom-space-bilingual{
    line-height: 4.2rem;
    font-size: 13px !important;
  }
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus, .react-calendar__tile--active, .react-calendar__navigation{
  background: #162d6e !important;
}

.react-calendar__navigation button {
  min-width: 50px;
  background: none;
  color: white;
  font-size: 16px;
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus{
  color:black;
}

.react-calendar__month-view__weekdays__weekday abbr[title]{
  font-size: 12px;
  text-decoration: none;
}

.btn-height{
  height: 11.14rem;
  font-size: 4.14rem;
  width: 45.21rem;
}

.snap-btn-text{
  font-size: 4.14rem;
}

.resume-switch-button button{
  height: 8.14rem;
  font-size: 39px;
  width: 25.14rem;
}

.sticky-footer{
  padding-bottom: 6rem !important;
}

@media only screen and (max-width: 651px) {
  .sticky-footer{
    padding-bottom: 9rem !important;
  }
}

@media only screen and (max-width: 430px) {
  .resume-switch-button button{
    height: 8.14rem;
    font-size: 35px;
    width: 25.14rem;
  }
}

.clickToReloadBtn{
  position: absolute;
  top: 58%;
  left: 0;
  right: 0;
  text-align: center;
}

.date-margin{
  margin-right: 2rem;
}

.payroll-filter-width{
  width: 55%;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 770px) {
  .filter-bilingual{
    width: 40%;
  }
  .date-margin{
    margin-right: 0rem;
    width: 100%;
  }
  .text-nowrap{
    white-space: nowrap;
  }
  .payroll-filter-width{
    width: 65%;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (min-width: 400px) and (max-width: 570px) {
  .job-margin-responsive{
    padding-bottom: 5rem !important;
  }
}