.pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem;
}
.pagination a {
  color: #357edd;
  text-decoration: none;
}
.page-item.disabled .page-link {
  color: #636c72;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-bottom-left-radius: .25rem;
  border-top-left-radius: .25rem;
}
.page-item:last-child .page-link {
  border-bottom-right-radius: .25rem;
  border-top-right-radius: .25rem;
}
.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #09819A;
  border-color: #09819A;
}
.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #357edd;
  background-color: #fff;
  border: 1px solid #ddd;
}
.page-link:focus, .page-link:hover {
  color: #014c8c;
  text-decoration: none;
  background-color: #eceeef;
  border-color: #ddd;
}
